<template>
  <a-card class="lession-videos nested-content__item">
    <div class="videos__header mb-2">
      <a-row :gutter="12">
        <a-col :span="12">
          <a-typography-title :level="3" :style="{ marginBottom: '12px' }">影片資源</a-typography-title>
        </a-col>
        <a-col :span="12" align="right">
          <a-button @click="openVdModal">新增影片資源</a-button>
        </a-col>
      </a-row>
    </div>

    <div class="videos__body">
      <template v-if="lessionStore.data.videos.length > 0">
        <a-row>
          <a-col :span="12" v-for="(item, idx) in lessionStore.data.videos">
            <a-card>
              <template #cover>
                <iframe
                  width="100%" height="250"
                  :src="`https://www.youtube.com/embed/${youtubeIdParser(item.url)}`"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </template>
              <a-card-meta
                :title="item.name"
                :description="item.description"
              />
              <template #actions>
                <!-- <a href="javascript:void(0)">編輯</a> -->
                <a-space />
                <a-popconfirm
                  title="確認刪除影片？"
                  ok-text="確定"
                  cancel-text="取消"
                  danger
                  @confirm="confirmDeleteVideo(idx)"
                  @cancel="cancelDeleteVideo"
                >
                  <a class="error--styled" href="javascript:void(0)" style="width: 100%;">刪除</a>
                </a-popconfirm>
              </template>
            </a-card>
          </a-col>
        </a-row>
      </template>

      <template v-else>
        <a-empty :image="simpleImage" description="無此資料" />
      </template>
    </div>
  </a-card>

  <VideoModal
    :visible="vdModalVisible"
    :mode="vdModalMode"
    @confirm="handleVdModalConfirm"
    @cancel="handleVdModalCancel"
  />
</template>

<script>
import { defineComponent } from 'vue'
import { message } from 'ant-design-vue'
import { Empty } from 'ant-design-vue'
import { useLessionStore } from '../store'
import api from '@/apis'
import VideoModal from './VideoModal'

export default defineComponent({
  components: {
    VideoModal
  },
  setup() {
    const lessionStore = useLessionStore();

    function youtubeIdParser(url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return (match&&match[7].length==11)? match[7] : false;
    }

    return {
      lessionStore,

      youtubeIdParser,

      /* Empty */
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  },
  data() {
    return {
      /* Video Modal */
      vdModalVisible: false,
      vdModalMode: 'insert' // insert|edit
    }
  },
  methods: {
    /* Video Modal */
    openVdModal(mode) {
      this.vdModalMode = mode;
      this.vdModalVisible = true;
    },
    async handleVdModalConfirm() {
      const response = await api.v1.lession.findOne(this.$route.params.lession_id);
      this.lessionStore.setData({ ...response.data.result });

      this.vdModalVisible = false;
    },
    handleVdModalCancel() {
      this.vdModalVisible = false;
    },

    /* Delete Confrim Control */
    async confirmDeleteVideo(idx) {
      let data = this.lessionStore.data.videos.map(x => ({...x}));
      data.splice(idx, 1);

      try {
        await api.v1.lession.updateOne(
          this.$route.params.lession_id,
          { videos: data }
        );

        // update data after deleted
        this.lessionStore.data.videos.splice(idx, 1);

        message.success('刪除影片資源成功');
      } catch (error) {
        message.error('刪除影片資源失敗');
        console.log(error);
      }
    },
    cancelDeleteVideo() {
       message.info('取消刪除動作');
    }
  }
})
</script>

<style lang="scss">
.lession-videos {
  .videos__body {
    .body-item {
      height: 200px;

      .item-title {
        font-size: 1.1rem !important;
        margin-bottom: 4px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 1.5715;
        font-weight: 500;
      }

      .item-description {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        line-height: 1.5715;
        margin-bottom: 4px;
      }
    }
  }
}
</style>