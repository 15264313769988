<template>
  <a-card class="lession-images nested-content__item">
    <div class="images__header mb-2">
      <a-row>
        <a-col :span="12">
          <a-typography-title :level="3" :style="{ marginBottom: '12px' }">課堂照片</a-typography-title>
        </a-col>
      </a-row>
    </div>

    <div class="images__body">
      <a-upload
        v-model:file-list="imageFileList"
        class="image-multi-uploader"
        action="/api/v1/file"
        accept=".png, .jpg, .jpeg"
        :before-upload="beforeUpload"
        :multiple="true"
        list-type="picture-card"
        @preview="handlePreview"
        @change="handleChange"
      >
        <div>
          <plus-outlined :style="{ marginBottom: '14px' }" />
          <div class="ant-upload-text">新增照片</div>
        </div>
      </a-upload>

      <a-modal
        :visible="previewVisible"
        :title="previewTitle"
        :footer="null"
        @cancel="handlePreviewCancel"
      >
        <img alt="preview-img" style="width: 100%" :src="previewImage" />
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import { useLessionStore } from '../store'
import api from '@/apis'

function _getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default defineComponent({
  components: {
    PlusOutlined
  },
  setup() {
    const route = useRoute();

    /* Store */
    const lessionStore = useLessionStore();

    /* Image Handler */
    // upload
    const imageFileList = ref([]);

    watch(() => lessionStore.data, function() {
      lessionStore.data.images.forEach(item => {
        imageFileList.value.push({
          code: item.uri,
          name: item.name,
          status: 'done',
          url: item.uri,
          thumbUrl: item.uri
        });
      });
    });

    const beforeUpload = file => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('請選擇 jpg 或 png 的圖片格式');
      }

      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('圖片大小不可以超過 2MB');
      }
      return isJpgOrPng && isLt2M;
    }

    const handleChange = async info => {
      if (info.file.status === 'done') {
        const imageObj = {
          name: info.file.name,
          uri: info.file.response.result.file_src,
          size: info.file.size
        }

        lessionStore.data.images.push(imageObj);
        await updateLessionIamges();
        message.success(`${info.file.name} 上傳成功`);
      }

      if (info.file.status === 'error') {
        message.error(`${info.file.name} 上傳失敗，請稍後再試`);
      }

      if (info.file.status === 'removed') {
        // find deleted image code
        let code = undefined;
        if (Object.keys(info.file).includes('response')) {
          code = info.file.response.result.file_src
        } else {
          code = info.file.file_src;
        }
        if (code == undefined) {
          console.log('Delete image error. Image code is undefined.');
          message.error(`${info.file.name} 照片刪除失敗，請稍後再試`);
          return
        }

        // find deleted index
        let index = undefined;
        lessionStore.data.images.forEach((item, idx) => {
          if (item.uri == code) index = idx;
        });
        if (index == undefined) {
          console.log('Delete image error. Index is undefined.');
          message.error(`${info.file.name} 照片刪除失敗，請稍後再試`);
          return
        }
        lessionStore.data.images.splice(index, 1);

        try {
          await updateLessionIamges();
          await deleteFile(code);
          message.success(`${info.file.name} 照片刪除成功`);
        } catch (error) {
          console.log(error);
          message.error(`${info.file.name} 照片刪除失敗，請稍後再試`);
        }
      }
    }

    // preview
    const previewVisible = ref(false);
    const previewImage = ref('');
    const previewTitle = ref('');

    const handlePreviewCancel = () => {
      previewVisible.value = false;
    }

    const handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await _getBase64(file.originFileObj);
      }

      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
      previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
    }

    /* Api */
    const updateLessionIamges = (async () => {
      const params = {
        images: lessionStore.data.images
      }

      await api.v1.lession.updateOne(
        route.params.lession_id,
        params
      );
    });

    const deleteFile = (async (code) => {
      const codeSplitList = code.split('/');
      const key = codeSplitList[codeSplitList.length-1];
      await api.v1.file.deleteOne({ key: key });
    });

    return {
      /* Image Handler */
      // updload
      imageFileList,
      beforeUpload,
      handleChange,
      
      // preview
      previewVisible,
      previewImage,
      previewTitle,
      handlePreview,
      handlePreviewCancel
    }
  },
  methods: {
    
  }
})
</script>