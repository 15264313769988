<template>
  <a-modal
    :wrap-class-name="[{ 'full-modal': device.mobile }]"
    class="lession-training-record-modal"
    :visible="visible"
    title="課堂訓練內容"
    :maskClosable="false"
  >
    <!-- Main -->
    <template v-if="lessionTrainingRecordData.length">
      <a-card
        v-for="(item, itemIdx) in lessionTrainingRecordData"
        class="training-action-card"
      >
        <a-row>
          <a-col :xs="22" :md="14">
            {{ item.action_name }}
          </a-col>
          <a-col :span="2" align="right" v-if="device.mobile">
            <a-button type="text" shape="circle" @click="deleteOne(item.id, itemIdx)">
              <template #icon>
                <span class="mdi mdi-close" :style="{ fontSize: '1.2rem' }" />
              </template>
            </a-button>
          </a-col>
          <a-col :xs="6" :md="4">
            {{ item.set }} 組
          </a-col>
          <a-col :xs="6" :md="4">
            <template v-if="item.rep">
              {{ item.rep }} 下
            </template>
            <template v-else-if="item.second">
              {{ item.second }} 秒
            </template>
            <template v-else-if="item.failure">
              力竭
            </template>
          </a-col>
          <a-col :md="2" align="right" v-if="!device.mobile">
            <a-button type="text" shape="circle" @click="deleteOne(item.id, itemIdx)">
              <template #icon>
                <span class="mdi mdi-close" :style="{ fontSize: '1rem' }" />
              </template>
            </a-button>
          </a-col>
        </a-row>
      </a-card>
    </template>

    <template v-else>
      <a-empty
        class="py-6"
        :image="simpleImage"
        description="還沒有紀錄訓練動作"
        :style="{ color: '#656565' }"
      />
    </template>
    <!-- Main -->

    <!-- Footer -->
    <template #footer>
      <a-form
        :model="formState"
        layout="vertical"
        autocomplete="off"
      >
        <a-row :gutter="8">
          <a-col :xs="24" :md="18">
            <a-form-item
              label="訓練動作"
              name="action_name"
              :class="{ 'mb-1': device.mobile }"
            >
              <a-auto-complete
                v-model:value="formState.action_name"
                :options="trainingOptions"
              />
            </a-form-item>
          </a-col>

          <a-col :xs="24" :lg="6">
            <a-form-item
              label="組數"
              name="set"
              :class="{ 'mb-4': device.mobile }"
            >
              <a-input-number
                v-model:value="formState.set"
                :min="1"
                style="width: 100%;"
              />
            </a-form-item>
          </a-col>

          <!-- <a-col :xs="9" :lg="4">
            <a-form-item
              label="次數"
              name="rep"
              :class="{ 'mb-1': device.mobile }"
            >
              <a-input-number
                v-model:value="formState.rep"
                :min="1"
                style="width: 100%;"
              />
            </a-form-item>
          </a-col> -->

          <a-col :span="24" align="left" class="mb-4">
            <a-radio-group
              v-model:value="statType"
              @change="tempFormValue = 1"
              :style="{ marginRight: '20px' }"
            >
              <a-radio-button value="rep">次數</a-radio-button>
              <a-radio-button value="second">時間</a-radio-button>
              <a-radio-button value="failure">力竭</a-radio-button>
            </a-radio-group>

            <a-input-number
              v-if="statType != 'failure'"
              v-model:value="tempFormValue"
              :min="1"
              style="width: calc(100% - 200px);"
            >
              <template #addonAfter v-if="statType == 'second'">秒</template>
            </a-input-number>
          </a-col>

          <a-col :xs="24" :lg="24" class="mb-2">
            <a-button type="primary" style="width: 100%;" @click="insertOne">
              新增
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </template>
    <!-- Footer -->
  </a-modal>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { message, Empty } from 'ant-design-vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import api from '@/apis'

export default defineComponent({
  props: ['visible'],
  components: {},
  setup() {
    const route = useRoute();
    const device = createDeviceDetector();

    /* Data */
    const lessionTrainingRecordData = ref([]);
    const courseTrainingRecordData = ref([]);

    /* FormState */
    const initialState = {
      action_name: '',
      set: 1, // 組數
      rep: null, // 組次數
      second: null, // 時間
      failure: null // 力竭
    }
    const formState = reactive({ ...initialState });
    function resetForm() {
      Object.assign(formState, initialState);
    }

    const statType = ref('rep'); // rep次數|second時間(秒)|failure力竭
    const tempFormValue = ref(1);

    /* Options */
    const trainingOptions = ref([]);
    const generateTrainingOptions = () => {
      trainingOptions.value = [];
      let actionNameRecords = [];

      courseTrainingRecordData.value.forEach(item => {
        if (!actionNameRecords.includes(item.action_name)) {
          trainingOptions.value.push({
            label: item.action_name,
            value: item.action_name
          });
          actionNameRecords.push(item.action_name);
        }
      });
    }

    /* Api */
    const getLessionTrainingRecords = (async () => {
      const response = await api.v1.lession.findTrainingRecords(
        route.params.course_id,
        route.params.lession_id,
      );
      lessionTrainingRecordData.value = response.data.result;
    });

    const getCourseTrainingRecords = (async () => {
      const response = await api.v1.course.findTrainingRecords(route.params.course_id);
      courseTrainingRecordData.value = response.data.result;
    });

    /* Mounted */
    onMounted(async () => {
      await getLessionTrainingRecords();
      await getCourseTrainingRecords();
      generateTrainingOptions();
    });

    return {
      device,

      /* Data */
      lessionTrainingRecordData,

      /* FormState */
      formState,
      resetForm,
      statType,
      tempFormValue,

      /* Options */
      trainingOptions,
      generateTrainingOptions,

      /* Api */
      getLessionTrainingRecords,
      getCourseTrainingRecords,

      /* Empty */
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  },
  watch: {
    visible() {
      if (this.visible) {
        this.tempFormValue = 1;
        this.resetForm();
      }
    }
  },
  methods: {
    async insertOne() {
      const err = this._checkRequried();
      if (err) {
        message.error(err);
        return
      }

      // 設置選擇的value
      if (this.statType) {
        this.formState[this.statType] = this.tempFormValue;
      }
      console.log(this.formState);

      try {
        await api.v1.lession.insertOneTrainingRecord(
          this.$route.params.course_id,
          this.$route.params.lession_id,
          this.formState
        );
        
        this.getLessionTrainingRecords();
        this.getCourseTrainingRecords();
        this.generateTrainingOptions();
        message.success('新增成功');
        this.resetForm();
      } catch (error) {
        console.log(error);
        message.error('新增訓練動作發生錯誤，請稍後再試');
      }

      console.log(this.lessionTrainingRecordData);
    },
    async deleteOne(id, itemIdx) {
      try {
        await api.v1.lession.deleteOneTrainingRecord(id);

        this.lessionTrainingRecordData.splice(itemIdx, 1);
        message.success('刪除成功');
      } catch (error) {
        console.log(error);
        message.error('刪除訓練動作發生錯誤，請稍後再試');
      }
    },

    /* Private func */
    _checkRequried() {
      let err = '';
      
      if (!this.formState.action_name) {
        err = '請輸入訓練動作名稱';
      }

      return err
    }
  }
})
</script>

<style lang="scss">
.lession-training-record-modal {
  // min-width: 600px;

  .training-action-card {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 12px;

    .ant-card-body {
      padding: 20px 28px;
    }
  }
}
</style>