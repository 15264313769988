import { defineStore } from 'pinia'

interface LessionData {
  id: number | undefined,
  name: string,
  description: string,
  start_date: string,
  end_date: string,
  equipments: Array<{id: number, name: string}>,
  homeworks: Array<{id: number, name: string, description: string}>,
  videos: Array<{name: string, description: string, url: string}>,
  images: Array<{name: string, uri: string, size: number}>
}

export const useLessionStore = defineStore('lession', {
  state: () => {
    return {
      data: {
        id: undefined,
        name: '',
        description: '',
        start_date: '',
        end_date: '',
        equipments: [],
        homeworks: [],
        videos: [],
        images: []
      } as LessionData,
      
      usersData: [] as any[],
      preRollcallData: [] as any[],
      preRollcallStatData: [] as any[],
      postRollcallData: [] as any[]
    }
  },
  getters: {},
  actions: {
    setData(data: LessionData) {
      this.data = data;
    },
    setUsersData(data: any) {
      this.usersData = data;
    },
    setRollcallsData(data: any[]) {
      this.preRollcallData = [];
      this.postRollcallData = [];

      data.forEach(item => {
        if (item.type == 'lession_pre') {
          this.preRollcallData.push(item);
        } else if (item.type == 'lession_post') {
          this.postRollcallData.push(item);
        }
      });

      // calculate preRollcall stat
      this.preRollcallStatData = [];

      const preCompletedUserIdList = this.preRollcallData.map(item => item.user_id);
      this.usersData.forEach(user => {
        let completed = false;
        if (preCompletedUserIdList.includes(user.uid)) completed = true;
        
        this.preRollcallStatData.push({
          member_id: user.member_id,
          name: user.name,
          completed: completed
        });
      });
    }
  }
});