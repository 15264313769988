<template>
  <a-card class="lession-stat nested-content__item">
    <a-row :gutter="16">
      <a-col :lg="6" :xs="12">
        <div class="stat-item" :class="{ 'mb-4': device.mobile }" align="center">
          <a-statistic :value="lessionStore.preRollcallData.length">
            <template #title>
              今日出席 <a-button type="link" size="small" @click="openRollcallDrawer">查看</a-button>
            </template>
            <template #suffix>
              <span>/ {{ lessionStore.usersData.length }}</span>
            </template>
          </a-statistic>
        </div>
      </a-col>
      <a-col :lg="6" :xs="12">
        <div class="stat-item" :class="{ 'mb-4': device.mobile }" align="center">
          <a-statistic title="回家作業" :value="lessionStore.data.homeworks.length" />
        </div>
      </a-col>
      <a-col :lg="6" :xs="12">
        <div class="stat-item" align="center">
          <a-statistic title="影片資源" :value="lessionStore.data.videos.length" />
        </div>
      </a-col>
      <a-col :lg="6" :xs="12">
        <div class="stat-item" align="center">
          <a-statistic title="課堂照片" :value="lessionStore.data.images.length" />
        </div>
      </a-col>
    </a-row>
  </a-card>

  <!-- Rollcall Drawer -->
  <a-drawer
    v-model:visible="rollcallDrawer.visible"
    title="今日出席"
    placement="right"
  >
    <a-table
      :dataSource="lessionStore.preRollcallStatData"
      :columns="rollcallDrawerTableColumns"
      size="small"
      :pagination="false"
      :scroll="{ x: 'max-content' }"
    >
      <template #bodyCell="{ column, text, record }">
        <template v-if="column.key === 'completed'">
          <span :style="{ color: green[5] }" v-if="record.completed">完成簽到</span>
          <span :style="{ color: red[5] }" v-else>未簽到</span>
        </template>
      </template>
    </a-table>

    <!-- <a-list :data-source="lessionStore.preRollcallData" :bordered="false">
      <template #renderItem="{ item }">
        
        <a-button type="text" style="height:40px; width:95%; text-align:left;">
          <span class="name" style="font-weight:500;">{{ item.name }}</span>
        </a-button>
      </template>
    </a-list> -->
  </a-drawer>
  <!-- Rollcall Drawer -->

  <!-- <RollcallDrawer
    :visible="rollcallDrawer.visible"
    @close="handleRollcallDrawerClose"
  /> -->
</template>

<script>
import { defineComponent } from 'vue'
import { green, red } from '@ant-design/colors'
import { createDeviceDetector } from 'next-vue-device-detector'
import RollcallDrawer from './RollcallDrawer.vue'
import { useLessionStore } from '../store'


const rollcallDrawerTableColumns = [
  {
    title: '學員編號',
    dataIndex: 'member_id',
    key: 'member_id',
  },
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '簽到',
    dataIndex: 'completed',
    key: 'completed',
  }
]

export default defineComponent({
  components: {
    RollcallDrawer
  },
  setup() {
    const device = createDeviceDetector();

    /* Store */
    const lessionStore = useLessionStore();

    return {
      device,

      /* Store */
      lessionStore,

      /* Rollcall Drawer */
      rollcallDrawerTableColumns,

      /* Antd */
      green,
      red
    }
  },
  data() {
    return {
      /* Rollcall Drawer */
      rollcallDrawer: {
        visible: false
      }
    }
  },
  methods: {
    /* Rollcall Drawer */
    openRollcallDrawer() {
      this.rollcallDrawer.visible = true;
    },
    handleRollcallDrawerClose() {
      this.rollcallDrawer.visible = false;
    }
  }
})
</script>

<style lang="scss">
.lession-stat {
  padding-bottom: 0 !important;
}
</style>