<template>
  <!-- Layout Header -->
  <a-layout-header class="layout-header" :style="{ height: device.mobile ? '50px' : '74px' }">
    <a-row>
      <a-col :lg="12" :xs="21">
        <a-button
          class="header-menu-btn"
          type="link" shape="circle"
          @click="appStore.collapsed=!appStore.collapsed"
        >
          <template #icon>
            <span class="mdi mdi-menu" :style="{ color: '#212121', fontSize: '1.5rem' }"></span>
          </template>
        </a-button>

        <div class="letwecare-breadcrumb">
          <a-breadcrumb
            v-if="!device.mobile"
            class="__breadcrumb"
            :routes="breadcrumbRoutes"
          >
            <template #itemRender="{ routes, route }">
              <span v-if="routes.indexOf(route) === routes.length - 1">
                {{ route.breadcrumbName }}
              </span>
              <router-link v-else :to="route.path">
                {{ route.breadcrumbName }}
              </router-link>
            </template>
          </a-breadcrumb>

          <a-page-header
            class="__page-header"
            :style="{ height: '63px', paddingLeft: 0 }"
            :title="lessionStore.data.name"
            @back="backToCoursePage"
          >
            <template #itemRender="{ route, params, routes, paths }">
              <span v-if="routes.indexOf(route) === routes.length - 1">{{route.breadcrumbName}}</span>
              <router-link v-else :to="paths.join('/')">{{route.breadcrumbName}}</router-link>
            </template>
          </a-page-header>
        </div>
      </a-col>

      <a-col :lg="12" :xs="3" align="right">
        <div :style="{ marginTop: '8px' }" v-if="!device.mobile">
          <a-button
            @click="openTrainingRecordModal"
            class="mr-3"
          >訓練內容</a-button>

          <a-dropdown>
            <template #overlay>
              <a-menu>
                <a-menu-item key="1" @click="openRollcallModal('lession_pre')">課前</a-menu-item>
                <a-menu-item key="2" @click="openRollcallModal('lession_post')">課後</a-menu-item>
              </a-menu>
            </template>
            <a-button>
              課堂簽到
              <down-outlined />
            </a-button>
          </a-dropdown>
        </div>

        <a-dropdown v-if="device.mobile">
          <template #overlay>
            <a-menu>
              <a-menu-item
                @click="openRollcallModal('lession_pre')"
                :style="{ fontSize: '1.1rem', lineHeight: '2rem' }"
              >
                課前簽到
              </a-menu-item>
              <a-menu-item
                @click="openRollcallModal('lession_post')"
                :style="{ fontSize: '1.1rem', lineHeight: '2rem' }"
              >
                課後調查
              </a-menu-item>

              <a-menu-divider />

              <a-menu-item
                @click="openTrainingRecordModal"
                :style="{ fontSize: '1.1rem', lineHeight: '2rem' }"
              >
                訓練內容
              </a-menu-item>

              <a-menu-divider />

              <a-menu-item
                @click="lessionInfoDrawer.visible = true"
                :style="{ fontSize: '1.1rem', lineHeight: '2rem' }"
              >
                課堂資訊
              </a-menu-item>
              <a-menu-item
                @click="lessionNoteDrawer.visible = true"
                :style="{ fontSize: '1.1rem', lineHeight: '2rem' }"
              >
                課堂紀錄
              </a-menu-item>
            </a-menu>
          </template>
          <a-button :class="device.mobile ? '' : 'mt-6'" type="text" shape="circle">
            <EllipsisOutlined :style="{ fontSize: '1.2rem' }" />
          </a-button>
        </a-dropdown>
      </a-col>
    </a-row>
  </a-layout-header>
  <!-- Layout Header --> 

  <!-- Layout Nested -->
  <a-layout class="app-layout-nested">
    <!-- Content -->
    <a-layout-content class="layout-nested-content">
      <div class="content__body fluid">
        <LessionStat />
        <LessionSurveys />
        <LessionHomeworks />
        <LessionVideos />
        <LessionImages />
      </div>
    </a-layout-content>
    <!-- Content -->

    <!-- Sider -->
    <a-layout-sider
      v-if="!device.mobile"
      class="lession-sider layout-nested-sider sider--wide"
      :style="{ padding: '20px' }"
    >
      <LessionInfo v-if="toggleValue == 'info'" />
      <LessionNote v-if="toggleValue == 'note'"></LessionNote>

      <a-button class="toggle-btn" type="text" @click="toggleSider">
        切換至
        <span v-if="toggleValue == 'info'">課堂紀錄</span>
        <span v-if="toggleValue == 'note'">課堂資訊</span>
      </a-button>
    </a-layout-sider>
    <!-- Sider -->
  </a-layout>
  <!-- Layout Nested -->

  
  <TrainingRecordModal
    :visible="trainingRecordModal.visible"
    @cancel="handleTrainingRecordModalCancel"
  />

  <RollcallModal
    :visible="rollcallModal.visible"
    :mode="rollcallModal.mode"
    @confirm="handleRollcallModalConfirm"
    @cancel="handleRollcalllModalCancel"
  />

  <LessionInfoDrawer
    :visible="lessionInfoDrawer.visible"
    @close="lessionInfoDrawer.visible = false"
  />

  <LessionNoteDrawer
    :visible="lessionNoteDrawer.visible"
    @close="lessionNoteDrawer.visible = false"
  />
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { createDeviceDetector } from 'next-vue-device-detector'
import { EllipsisOutlined, DownOutlined } from '@ant-design/icons-vue'
import { useAuthStore } from '@/store/auth'
import { useAppStore } from '@/store/app'
import { useLessionStore } from './store'
import api from '@/apis'
import LessionStat from './components/LessionStat'
import LessionSurveys from './components/LessionSurveys'
import LessionHomeworks from './components/LessionHomeworks'
import LessionVideos from './components/LessionVideos'
import LessionImages from './components/LessionImages'
import LessionInfo from './components/LessionInfo'
import LessionNote from './components/LessionNote'
import TrainingRecordModal from './components/TrainingRecordModel'
import RollcallModal from './components/RollcallModal'
import LessionInfoDrawer from './components/LessionInfoDrawer'
import LessionNoteDrawer from './components/LessionNoteDrawer'

export default defineComponent({
  components: {
    LessionStat,
    LessionSurveys,
    LessionHomeworks,
    LessionVideos,
    LessionImages,
    LessionInfo,
    LessionNote,
    TrainingRecordModal,
    RollcallModal,
    LessionInfoDrawer,
    LessionNoteDrawer,
    EllipsisOutlined,
    DownOutlined
  },
  setup() {
    const route = useRoute();
    const device = createDeviceDetector();

    /* Store */
    const appStore = useAppStore();
    const lessionStore = useLessionStore();

    /* Breadcrumbs */
    const breadcrumbRoutes = ref([
      {
        path: '/app/courses',
        breadcrumbName: '班級管理'
      }
    ]);

    /* Data */
    const courseData = ref({});
    const lessionData = ref({});

    /* Api */
    const getCourseData = (async () => {
      courseData.value = {};
      
      const response = await api.v1.course.findOne(route.params.course_id);
      courseData.value = response.data.result;
    });

    const getLessionData = (async () => {
      lessionData.value = {};

      const response = await api.v1.lession.findOne(route.params.lession_id);
      lessionStore.setData({ ...response.data.result });
      console.log(lessionStore.data);

      lessionData.value = response.data.result;
    });

    const getUserMembers = (async () => {
      const response = await api.v1.course.findMembers('user', {
        course_id: route.params.course_id
      });
      lessionStore.setUsersData([ ...response.data.result ]);
    });

    const getLessionRollcallData = (async () => {
      const response = await api.v1.lession.findRollcalls(route.params.course_id, route.params.lession_id);
      lessionStore.setRollcallsData([ ...response.data.result ]);
    });

    /* Mounted */
    onMounted(async () => {
      await getCourseData();
      await getLessionData();
      await getUserMembers();
      await getLessionRollcallData();

      // set routes breadcrumb
      breadcrumbRoutes.value.push({
        path: `/app/course/${ route.params.course_id }`,
        breadcrumbName: courseData.value.name
      });
      breadcrumbRoutes.value.push({
        path: `/app/course/${ route.params.course_id }/lession/${ route.params.lession_id }`,
        breadcrumbName: lessionData.value.name
      });
    });

    return {
      device,

      /* Store */
      appStore,
      lessionStore,

      /* Routes breadcrumb */
      breadcrumbRoutes,

      /* Data */
      courseData,
      lessionData,

      /* Api */
      getLessionRollcallData
    }
  },
  data() {
    return {
      /* Toggle Sider: Info & Note */
      toggleValue: 'note',

      /* Training Record Modal */
      trainingRecordModal: {
        visible: false
      },

      /* Rollcall Modal */
      rollcallModal: {
        visible: false,
        mode: 'pre' // pre|post
      },

      /* Lession Info Drawer */
      lessionInfoDrawer: {
        visible: false
      },

      /* Lession Note Drawer */
      lessionNoteDrawer: {
        visible: false
      }
    }
  },
  methods: {
    /* router */
    backToCoursePage() {
      this.$router.push({
        name: 'AppCoursePage',
        params: {
          course_id: this.$route.params.course_id
        }
      })
    },

    /* Toggle Sider */
    toggleSider() {
      if (this.toggleValue == 'info') this.toggleValue = 'note';
      else this.toggleValue = 'info';
    },

    /* Training Record Modal */
    openTrainingRecordModal() {
      this.trainingRecordModal.visible = true;
    },
    handleTrainingRecordModalCancel() {
      this.trainingRecordModal.visible = false;
    },

    /* Rollcall Modal */
    openRollcallModal(mode) {
      this.rollcallModal.mode = mode;
      this.rollcallModal.visible = true;
    },
    handleRollcallModalConfirm() {
      this.getLessionRollcallData();
      this.rollcallModal.visible = false;
    },
    handleRollcalllModalCancel() {
      this.rollcallModal.visible = false;
    }
  }
})
</script>

<style lang="scss">
.lession-sider {
  position: relative;

  .toggle-btn {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>