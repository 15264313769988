<template>
  <a-modal
    wrapClassName="lession-rollcall-modal full-modal"
    :visible="visible"
    :title="title"
    :footer="false"
    :maskClosable="false"
    width="100%"
  >
    <a-form
      :model="formState"
      layout="vertical"
      autocomplete="off"
      :style="{ width: '80%', maxWidth: '800px', margin: '0 auto' }"
    >
      <a-row>
        <a-col :span="24" align="center">
          <a-form-item
            name="member_id"
          >
            <div :style="{ fontSize: '1.2rem', marginBottom: '8px' }">選擇班級成員</div>
            <a-select
              v-model:value="formState.member_id"
              :options="userOptions"
              :filterOption="filteredOptions"
              @change="changeUser"
              size="large"
              showSearch
              allowClear
              optionLabelProp="label"
              placeholder="輸入姓名或會員編號"
              :style="{ width: '100%', maxWidth: '300px', fontSize: '1.2rem' }"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-divider />

      <!-- 簽到內容 -->
      <div :style="{ width: '100%', maxWidth: '450px', margin: '0 auto' }">
        <div
          class="letwecare-survey-card"
          v-for="survey in surveyData.contents"
        >
          <div class="card-title">{{ survey.name }}</div>
          <div class="card-body" align="center">
            <template v-if="actionState == 'edit'">
              <template v-if="survey.type == 'number'">
                <a-input-number
                  v-model:value="survey.value"
                  class="body-number-input"
                  size="large"
                  :min="1"
                />
              </template>

              <template v-if="survey.type == 'text-field'">
                <a-input
                  v-model:value="survey.value"
                  class="body-input"
                  size="large"
                />
              </template>

              <template v-if="survey.type == 'radio'">
                <a-radio-group v-model:value="survey.value">
                  <a-radio  
                    v-for="opt in survey.selection_items"
                    :value="opt.label"
                    :style="{ display: 'flex', height: '40px', lineHeight: '40px', fontSize: '1.3rem' }"
                  >{{ opt.label }}</a-radio>
                </a-radio-group>
              </template>

              <template v-if="survey.type == 'checkbox'">
                <a-checkbox-group v-model:value="survey.value">
                  <a-checkbox  
                    v-for="opt in survey.selection_items"
                    :value="opt.label"
                    :style="{ display: 'flex', height: '40px', lineHeight: '40px', fontSize: '1.3rem', marginLeft: 0 }"
                  >{{ opt.label }}</a-checkbox>
                </a-checkbox-group>
              </template>

              <template v-if="survey.type == 'slider'">
                <span :style="{ fontSize: '1.8rem', fontWeight: '500' }">{{ survey.value }}</span>
                <a-slider
                  v-model:value="survey.value"
                  :min="survey.rule.min"
                  :max="survey.rule.max"
                />
              </template>
            </template>

            <template v-if="actionState == 'lock'">
              <template v-if="Array.isArray(survey.value)">
                <div class="body-value-text text--small" v-for="text in survey.value">{{ text }}</div>
              </template>

              <template v-else>
                <span class="body-value-text" :class="{ 'text--small': survey.value.length>6 }">{{ survey.value }}</span>
              </template>
            </template>
            
            <span class="body-unit" v-if="survey.unit">{{ survey.unit }}</span>
          </div>
        </div>
      </div>
      <!-- 簽到內容 -->

      <!-- Actions -->
      <div class="modal__actions" v-if="formState.member_id">
        <template v-if="actionState=='edit' && !Object.keys(rollcallData).length">
          <div class="action-btn" @click="submit">完成簽到</div>
        </template>
        
        <template v-if="actionState=='lock' && Object.keys(rollcallData).length">
          <div class="action-btn btn--white" @click="actionState='edit'">修改紀錄</div>
        </template>

        <template v-if="actionState=='edit' && Object.keys(rollcallData).length">
          <div class="action-btn btn--white mb-4" @click="actionState='lock'">取消</div>
          <div class="action-btn" @click="updateRollcallContents">保存紀錄</div>
        </template>
      </div>
      <!-- Actions -->
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { useLessionStore } from '../store'
import api from '@/apis'

export default defineComponent({
  props: ['visible', 'mode'],
  components: {},
  setup() {
    const route = useRoute();

    /* Store */
    const lessionStore = useLessionStore();

    /* Form */
    const initialState = {
      member_id: ''
    }
    const formState = reactive({ ...initialState });
    function resetForm() {
      Object.assign(formState, initialState);
    }

    /* Action State */
    const actionState = ref('edit'); // 編輯狀態 (edit|lock)

    /* Users Data */
    const usersData = ref([]);
    const userOptions = ref([]);
    function filteredOptions(input, option) {
      return option.label.includes(input) || option.value.includes(input)
    }

    /* Survey & Rollcall Data */
    const surveyData = ref({});
    const rollcallData = ref([]);

    /* Api */
    const getUserMembers = (async () => {
      const response = await api.v1.course.findMembers('user', {
        course_id: route.params.course_id
      });
      usersData.value = [...response.data.result];
      
      response.data.result.forEach(item => {
        userOptions.value.push({
          value: item.member_id,
          label: item.name
        });
      });
    });

    const getCourseSurveyData = (async (mode) => {
      surveyData.value = {};

      const response = await api.v1.course.surveyList(route.params.course_id, {
        type: mode
      });
      const result = response.data.result;

      if (result.length) {
        surveyData.value = result[result.length-1];

        // 加入 value 欄位
        surveyData.value.contents.forEach(item => {
          if (item.type == 'slider') {
            item.value = item.rule.min;
          }
          else {
            item.value = '';
          }
        });
      }

      console.log(surveyData.value)
    });

    const getRollcallData = (async (user_id, mode) => {
      rollcallData.value = {};

      try {
        const response = await api.v1.lession.findOneRollcall(
          route.params.course_id,
          route.params.lession_id,
          {
            user_id: user_id,
            type: mode
          }
        );
        rollcallData.value = response.data.result;

        // 放入已簽到的資料
        surveyData.value.contents.forEach(surveyContentItem => {
          rollcallData.value.contents.forEach(rollcallContentItem => {
            if (surveyContentItem.name == rollcallContentItem.name) {
              surveyContentItem.value = rollcallContentItem.value;
            }
          });
        });

        console.log(response.data.result);
      } catch (error) {
        console.log(error)
      }

      if (Object.keys(rollcallData.value).length > 0) {
        actionState.value = 'lock';
      } else {
        actionState.value = 'edit';
      }
      console.log(actionState.value)
    });

    /* Mounted */
    onMounted(async () => {
      await getUserMembers();
    });

    return {
      /* Store */
      lessionStore,

      /* Form */
      formState,
      resetForm,

      /* Action State */
      actionState,

      /* Data */
      // Users
      usersData,
      userOptions,
      filteredOptions,

      // Survey & Rollcall
      surveyData,
      rollcallData,

      /* Api */
      getCourseSurveyData,
      getRollcallData
    }
  },
  watch: {
    visible() {
      if (this.visible) {
        this.resetForm();
        this.surveyData = {};
        this.rollcallData = [];
      }
    }
  },
  computed: {
    title() {
      if (this.mode == 'lession_pre') return '課前簽到'
      if (this.mode == 'lession_post') return '課後記錄'
    }
  },
  methods: {
    async changeUser() {
      let user_id = undefined;
      this.usersData.forEach(item => {
        if (item.member_id == this.formState.member_id) user_id = item.uid;
      });
      await this.getCourseSurveyData(this.mode);
      await this.getRollcallData(user_id, this.mode);
    },

    /* Function */
    async submit() {
      let user_id = undefined;
      this.usersData.forEach(item => {
        if (item.member_id == this.formState.member_id) user_id = item.uid;
      });

      const paramsData = {
        type: this.mode,
        user_id: user_id,
        contents: this.surveyData.contents
      }

      try {
        await api.v1.lession.insertOneRollcall(
          this.$route.params.course_id,
          this.$route.params.lession_id,
          paramsData
        );
        
        this.getRollcallData(user_id, this.mode);
        this.actionState = 'lock';
        message.success('完成簽到');
      } catch (error) {
        console.log(error);
        message.error('簽到發生錯誤，請稍後再試');
      }
    },
    async updateRollcallContents() {
      const paramsData = {
        contents: this.surveyData.contents
      }

      try {
        await api.v1.lession.updateOneRollcall(
          this.rollcallData.id,
          paramsData
        );
        this.actionState = 'lock';
        message.success('保存成功');
      } catch (error) {
        console.log(error);
        message.error('保存紀錄發生錯誤，請稍後再試');
      }
    },
    
    // FIXME: 不要了
    async handleOk() {
      console.log(this.formState);

      const err = this._checkRequired();
      if (err) {
        message.error(err);
        return
      }
      
      // member_id -> user_id
      let user_id = '';
      this.usersData.forEach(u => {
        console.log(u);
        if (u.member_id == this.formState.member_id) user_id = u.uid;
      });
      console.log(user_id)

      this.formState.user_id = user_id;
      delete this.formState.member_id;

      // insert rollcall
      try {
        this.formState.type = this.mode;
        await api.v1.lession.insertOneRollcall(this.$route.params.course_id, this.$route.params.lession_id, this.formState);
        message.success('完成簽到');
      } catch (error) {
        console.log(error);
        message.error('會員簽到發生錯誤，請稍後再試');
      }

      this.resetForm();
      this.$emit('confirm');
    },

    /* private func */
    _checkRequired() {
      let err = '';

      if (this.formState.member_id == '') {
        err = '請選擇成員';
        return err
      }
      
      // check record
      let keys = [];
      if (this.mode == 'pre') {
        keys = Object.keys(this.preSchema);
      } else if (this.mode == 'post') {
        keys = Object.keys(this.postSchema);
      }

      for (let i=0; i<keys.length; i++) {
        const key = keys[i];
        if (this.formState.record[key] == '') {
          err = '請填入所有欄位';
          break
        }
      }

      return err
    }
  }
})
</script>

<style lang="scss">
.lession-rollcall-modal {
  .modal__actions {
    padding: 40px 0;

    .action-btn {
      display: block;
      width: 100%;
      max-width: 270px;
      height: 52px;
      color: white;
      background: #FF8A00;
      // box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.05);
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 52px;
      text-align: center;
      cursor: pointer;
      margin: 0 auto;

      &.btn--white {
        color: #212121;
        background: white !important;
        border: 1.5px solid #E0E0E0;
      }
    }
  }
}
</style>