<template>
  <div class="lession-info">
    <div class="info__header">
      <a-row>
        <a-col :span="16">
          <div class="header-title">課堂資訊</div>
        </a-col>
        <a-col :span="8" align="right">
          <a-button
            type="text"
            size="small"
            :style="{ color: editing ? '#1890ff' : '#909090' }"
            @click="editingToggle"
          >
            {{ editing ? '保存' : '編輯' }}
          </a-button>
        </a-col>
      </a-row>
      
      <a-divider :style="{ margin: '8px 0' }" />
    </div>

    <div class="info__body">
      <div class="info-item">
        <div class="item-label">課堂名稱</div>
        <div class="item-field">
          <a-input
            v-model:value="lessionStore.data.name"
            size="large"
            :readonly="!editing"
          />
        </div>
      </div>

      <div class="info-item">
        <div class="item-label">課堂說明</div>
        <div class="item-field">
          <a-textarea
            v-model:value="lessionStore.data.description"
            size="large" auto-size
            :readonly="!editing"
          />
        </div>
      </div>

      <div class="info-item">
        <div class="item-label">上課日期</div>
        <div class="item-field">
          <a-date-picker
            v-model:value="tempStartDate"
            :show-time="{ format: 'YYYY-MM-DD' }"
            @change="onChangeStartDate"
            format="YYYY-MM-DD"
            :disabled="!editing"
            size="large"
            :style="{ width: '100%' }"
          />
        </div>
      </div>

      <div class="info-item">
        <div class="item-label">上課時間</div>
        <div class="item-field">
          <a-time-picker
            v-model:value="tempStartTime"
            @change="onChangeStartTime"
            :show-time="{ format: 'HH:mm' }"
            format="HH:mm"
            :disabled="!editing"
            size="large"
            :style="{ width: '100%' }"
          />
        </div>
      </div>

      <a-divider />

      <a-button :style="{ width: '100%', marginBottom: '16px' }" @click="openPostponeModal">延期</a-button>
      <a-button danger :style="{ width: '100%' }" @click="openDeleteConfirmModal">刪除課堂</a-button>

      <!-- <div class="info-item">
        <div class="item-label">課堂器材</div>
        <div class="item-field">
          <a-select
            v-model:value="lessionStore.data.equipments"
            mode="multiple"
            placeholder="選擇或是輸入器材名稱"
            :options="equipmentOptions"
            :disabled="!editing"
            size="large"
            :style="{ width: '100%' }"
          />
        </div>
      </div> -->
    </div>
  </div>

  <PostponeModal
    :visible="postponeModal.visible"
    :lessionInfo="postponeModal.lessionInfo"
    @complete="handlePostponeModalComplete"
    @cancel="handlePostponeModalClose"
  />

  <DeleteConfirmModal
    :visible="deleteConfirmModal.visible"
    titleText="課堂"
    :confirmationCode="deleteConfirmModal.code"
    @cancel="handleDeleteConfirmModalClose"
    @confirm="handleDeleteConfirmModalConfirm"
  />
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { notification } from 'ant-design-vue'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import { useLessionStore } from '../store'
import api from '@/apis'
import PostponeModal from '@/components/App/Lession/PostponeModal'
import DeleteConfirmModal from '@/components/ui/DeleteConfirmModal'

export default defineComponent({
  components: {
    PostponeModal,
    DeleteConfirmModal
  },
  setup() {
    const lessionStore = useLessionStore();

    /* Eqipment Options */
    const equipmentOptions = reactive([{
      value: '壺鈴',
      label: '壺鈴'
    }, {
      value: '健身環',
      label: '健身環'
    }, {
      value: '心律帶',
      label: '心律帶'
    }]);

    /* Start Datetime Picker */
    const tempStartDate = ref('');
    const tempStartTime = ref('');

    onMounted(async () => {
      tempStartDate.value = dayjs(lessionStore.data.start_date, 'YYYY-MM-DD');
      tempStartTime.value = dayjs(lessionStore.data.start_time, 'HH:mm');
    });

    return {
      lessionStore,

      /* Editing */
      editing: ref(false),

      /* Options */
      equipmentOptions,

      /* Start Datetime Picker */
      tempStartDate,
      tempStartTime,

      /* Antd */
      message,
      notification,

      /* Dayjs */
      dayjs,
      
      /* Api */
      api
    }
  },
  data() {
    return {
      /* Postpone Modal */
      postponeModal: {
        visible: false,
        lessionInfo: {}
      },

      /* Delete Confirm Modal */
      deleteConfirmModal: {
        visible: false,
        code: null
      }
    }
  },
  methods: {
    /* Router */
    toCoursePage() {
      this.$router.push({
        name: 'AppCoursePage',
        params: {
          course_id: this.$route.params.course_id
        }
      });
    },

    /* Save */
    async editingToggle() {
      if (!this.editing) this.editing = true;
      else {
        const params = {
          name: this.lessionStore.data.name,
          description: this.lessionStore.description,
          equipments: this.lessionStore.equipments,
          start_date: this.lessionStore.data.start_date,
          start_time: this.lessionStore.data.start_time
        }

        try {
          await api.v1.lession.updateOne(
            this.$route.params.lession_id,
            params
          );
          message.success('更新課堂資料成功');
        } catch (error) {
          console.log(error);
          message.error('更新課堂資料發生錯誤，請稍後再試');

          const response = await api.v1.lession.findOne(this.$route.params.lession_id);
          this.lessionStore.setData({ ...response.data.result });
        } finally {
          this.editing = false;
        }
      }
    },

    /* Postpone Modal */
    openPostponeModal() {
      this.postponeModal.lessionInfo = this.lessionStore.data;
      this.postponeModal.visible = true;
    },
    handlePostponeModalComplete() {
      this.postponeModal.visible = false;
    },
    handlePostponeModalClose() {
      this.postponeModal.visible = false;
    },
    
    /* Delete Confirm Modal */
    openDeleteConfirmModal() {
      this.deleteConfirmModal.code = this.lessionStore.data.name;
      this.deleteConfirmModal.visible = true;
    },
    handleDeleteConfirmModalClose() {
      this.deleteConfirmModal.visible = false;
    },
    async handleDeleteConfirmModalConfirm() {
      try {
        await this.api.v1.lession.deleteOne(this.lessionStore.data.id);
        message.success('刪除課堂成功');
        this.toCoursePage();
      } catch (error) {
        message.error('刪除課堂資料發生錯誤，請稍後再試');
      }
    },

    /* Start Datetime */
    onChangeStartDate() {
      this.lessionStore.data.start_date = dayjs(this.tempStartDate).format('YYYY-MM-DD');
      console.log(this.lessionStore.data)
    },
    onChangeStartTime() {
      this.lessionStore.data.start_time = dayjs(this.tempStartTime).format('HH:mm');
      console.log(this.lessionStore.data)
    },
  }
})
</script>

<style lang="scss">
.lession-info {
  height: calc(100% - 30px);

  .info__header {
    .header-title {
      font-size: 1rem;
      font-weight: 450;
    }
  }

  .info__body {
    .info-item {
      margin-bottom: 24px;

      .item-label {
        color: #424242;
        font-size: .9rem;
        font-weight: 400;
        letter-spacing: 0.025rem;
        margin-bottom: 4px;
      }
    }
  }
}
</style>