<template>
  <a-drawer
    v-model:visible="visible"
    title="今日出席"
    placement="right"
    @close="handleClose"
  >
    <a-list :data-source="lessionStore.preRollcallData" :bordered="false">
      <template #renderItem="{ item }">
        <a-button type="text" style="height:40px; width:95%; text-align:left;">
          <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" style="margin-right:10px;" size="small" />
          <span class="name" style="font-weight:500;">{{ item.name }}</span>
        </a-button>
      </template>
    </a-list>
  </a-drawer>
</template>

<script>
import { defineComponent } from 'vue'
import { useLessionStore } from '../store'

export default defineComponent({
  props: ['visible'],
  components: {},
  setup() {
    const lessionStore = useLessionStore();

    return {
      lessionStore
    }
  },
  methods: {
    handleClose() {
      this.$emit('close');
    }
  }
})
</script>