<template>
  <div class="lession-note">
    <div class="note__header">
      <a-row>
        <a-col :span="16">
          <div class="header-title">課堂記錄 ({{ notesData.length }})</div>
        </a-col>
      </a-row>
      
      <a-divider :style="{ margin: '8px 0' }" />
    </div>

    <div class="note__body">
      <div
        class="body-item"
        v-for="note in notesData"
      >
        <div class="text" v-html="note.content"></div>
        <div class="datetime">{{ DateUtil.formatDatetime(note.created_datetime) }}</div>
      </div>
    </div>

    <div class="note__actions">
      <a-mentions
        v-model:value="contentState.content"
        class="mb-2"
        rows="3"
        placeholder="您可以用 @ 來標記課堂學員"
        @select="selectMentionUsers"
      >
        <a-mentions-option
          v-for="item in usersData"
          :value="item.uid"
        >
          {{ item.name }}
        </a-mentions-option>
      </a-mentions>

      <a-button @click="insertOneLessionNote">送出</a-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import api from '@/apis'
import DateUtil from '@/utils/DateUtil'

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();

    /* Content State */
    const initialState = {
      content: '',
      mentionUsers: []
    }
    const contentState = reactive({ ...initialState });

    function resetContent() {
      Object.assign(contentState, initialState);
    }

    /* Data */
    const usersData = ref([]);
    const userIdNameMapper = ref([]);
    const notesData = ref([]);
    const tempSelectedLabelIdMapper = ref({}); // 存暫時提及的 {label:uid}, e.g. @蘇靖軒:u151234

    /* Api */
    const getUserMembers = (async () => {
      const response = await api.v1.course.findMembers('user', {
        course_id: route.params.course_id
      });

      response.data.result.forEach(item => {
        userIdNameMapper.value[item.uid] = item.name
      });
      usersData.value = response.data.result;
    });

    const getLessionNotes = (async () => {
      const response = await api.v1.lession.findNotes(route.params.course_id, route.params.lession_id);

      response.data.result.forEach(item => {
        // 替換 metion label -> button
        item.mention_users.forEach(uid => {
          const buttonTemplate = `<span class="text--link">@${userIdNameMapper.value[uid]}</span> `;
          const replaceLabel = `@${userIdNameMapper.value[uid]} `

          item.content = item.content.replace(replaceLabel, buttonTemplate);
        });
      });
      notesData.value = response.data.result;
    });

    const insertOneLessionNote = (async () => {
      contentState.mentionUsers = [];

      // set mention users
      Object.keys(tempSelectedLabelIdMapper.value).forEach(label => {
        if (contentState.content.includes(label)) {
          contentState.mentionUsers.push(tempSelectedLabelIdMapper.value[label])
        }
      });
      contentState.mentionUsers = [...new Set(contentState.mentionUsers)];

      try {
        await api.v1.lession.insertOneNote(route.params.course_id, route.params.lession_id, {
          content: contentState.content,
          mention_users: contentState.mentionUsers
        });

        resetContent();
        getLessionNotes();
        message.success('成功送出記錄');
      } catch (error) {
        console.log(error);
        message.error('送出課堂記錄失敗，請稍後再試');
      }
    });

    /* Mounted */
    onMounted(async () => {
      await getUserMembers();
      await getLessionNotes();
    });

    return {
      /* Content State */
      contentState,

      /* Data */
      usersData,
      userIdNameMapper,
      notesData,
      tempSelectedLabelIdMapper,

      /* Api */
      getLessionNotes,
      insertOneLessionNote,

      /* Utils */
      DateUtil
    }
  },
  methods: {
    selectMentionUsers(option) {
      const selectedUid = option.value;
      const label = this.userIdNameMapper[selectedUid];
      this.contentState.content = this.contentState.content.replace(`@${selectedUid} `, `@${label} `)
      
      this.tempSelectedLabelIdMapper[`@${label} `] = selectedUid;
    }
  }
})
</script>

<style lang="scss">
.lession-note {
  height: calc(100% - 20px);

  .note__header {
    .header-title {
      font-size: 1rem;
      font-weight: 450;
    }
  }

  .note__body {
    height: calc(100% - 140px);
    padding: 4px 0;
    overflow: auto;

    .body-item {
      padding: 12px 12px 8px 12px;
      border-radius: 4px;
      background: #FAFAFA;
      border: 1px solid #E0E0E0;
      margin-bottom: 8px;

      .text {
        padding-bottom: 8px;
      }

      .text--link {
        color: #1890FF;
        font-weight: 450;
        cursor: pointer;
      }

      .datetime {
        font-size: .5rem;
        color: #757575;
        text-align: right;
      }
    }
  }

  .note__actions {
    padding-top: 12px;
  }
}
</style>