<template>
  <a-drawer
    placement="right"
    :visible="visible"
    @close="onClose"
  >
    <LessionInfo />
  </a-drawer>
</template>

<script>
import { defineComponent } from 'vue'
import LessionInfo from './LessionInfo.vue'

export default defineComponent({
  props: ['visible'],
  components: {
    LessionInfo
  },
  setup() {
    return {}
  },
  methods: {
    onClose() {
      this.$emit('close');
    }
  }
})
</script>