<template>
  <a-card class="lession-homeworks nested-content__item">
    <div class="homeworks__header mb-2">
      <a-row>
        <a-col :span="12">
          <a-typography-title :level="3" :style="{ marginBottom: '12px' }">回家作業</a-typography-title>
        </a-col>
        <a-col :span="12" align="right">
          <a-button @click="openHwModal">新增回家作業</a-button>
        </a-col>
      </a-row>
    </div>

    <div class="homeworks__body">
      <a-list
        class="homework-list"
        item-layout="horizontal"
        :data-source="lessionStore.data.homeworks"
      >
        <template #renderItem="{ item, index }">
          <a-list-item class="list-item">
            <template #actions>
              <a-popconfirm
                title="確認刪除作業？"
                ok-text="確定"
                cancel-text="取消"
                danger
                @confirm="confirmDeleteHw(index)"
                @cancel="cancelDeleteHw"
              >
                <a-button type="text" danger>刪除</a-button>
              </a-popconfirm>
            </template>
            
            <a-list-item-meta :description="item.description">
              <template #title>
                <span class="item-title">{{ item.name }}</span>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </div>
  </a-card>

  <HomeworkModal
    :visible="hwModalVisible"
    :mode="hwModalMode"
    @confirm="handleHwModalConfirm"
    @cancel="handleHwModalCancel"
  />
</template>

<script>
import { defineComponent } from 'vue'
import { message } from 'ant-design-vue'
import { useLessionStore } from '../store'
import api from '@/apis'
import HomeworkModal from './HomeworkModal'

export default defineComponent({
  props: ['data'],
  components: {
    HomeworkModal
  },
  setup() {
    const lessionStore = useLessionStore();

    return {
      lessionStore
    }
  },
  data() {
    return {
      /* Homework Modal */
      hwModalVisible: false,
      hwModalMode: 'insert' // insert|edit
    }
  },
  methods: {
    /* Homework Modal */
    openHwModal(mode) {
      this.hwModalMode = mode;
      this.hwModalVisible = true;
    },
    async handleHwModalConfirm() {
      const response = await api.v1.lession.findOne(this.$route.params.lession_id);
      this.lessionStore.setData({ ...response.data.result });
      
      this.hwModalVisible = false;
    },
    handleHwModalCancel() {
      this.hwModalVisible = false;
    },

    /* Delete Confrim Control */
    async confirmDeleteHw(idx) {
      let data = this.lessionStore.data.homeworks.map(x => ({...x}));
      data.splice(idx, 1);
      console.log(data)

      try {
        await api.v1.lession.updateOne(
          this.$route.params.lession_id,
          { homeworks: data }
        );

        // update data after deleted
        this.lessionStore.data.homeworks.splice(idx, 1);

        message.success('刪除回家作業成功');
      } catch (error) {
        message.error('刪除回家作業失敗');
        console.log(error);
      }
    },
    cancelDeleteHw() {
      message.info('取消刪除動作');
    }
  }
})
</script>

<style lang="scss">
.lession-homeworks {
  .homeworks__body {
    .homework-list {
      .list-item {
        .item-title {
          font-size: 1.1rem !important;
        }
      }
    }
  }
}
</style>