<template>
  <a-modal
    :visible="visible"
    title="新增回家作業"
    okText="確認"
    cancelText="取消"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <template v-if="mode === 'form'">
      <!-- Coach -->
      <template v-if="authStore.info.role == 2">
        <div class="py-4" align="center">
          <a-button size="large" @click="pickFromMaterial">
            <FolderFilled :style="{ color: '#424242', fontSize: '1rem' }" />
            從資源庫選取
          </a-button>
        </div>
        <a-divider>或</a-divider>
      </template>
      <!-- Coach -->

      <a-form
        :model="formState"
        layout="vertical"
        autocomplete="off"
      >
        <a-form-item
          label="作業名稱"
          name="name"
          :rules="[{ required: true, message: '請輸入作業名稱' }]"
        >
          <a-input v-model:value="formState.name" size="large" />
        </a-form-item>

        <a-form-item
          label="作業描述"
          name="description"
          :rules="[{ required: true, message: '請輸入作業描述' }]"
        >
          <a-input v-model:value="formState.description" size="large" />
        </a-form-item>
      </a-form>
    </template>
    
    <template v-if="mode === 'material'">
      <a-directory-tree
        v-model:selectedKeys="selectedKeys"
        :load-data="getMaterialInfo"
        :tree-data="treeData"
      />
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { message } from 'ant-design-vue'
import { FolderFilled } from '@ant-design/icons-vue'
import { useAuthStore } from '@/store/auth'
import { useLessionStore } from '../store'
import api from '@/apis'

export default defineComponent({
  props: ['visible'],
  components: {
    FolderFilled
  },
  setup() {
    const authStore = useAuthStore();
    const lessionStore = useLessionStore();

    /* FormState */
    const initialState = {
      id: '',
      resource_id: 0,
      name: '',
      description: ''
    }
    const formState = ref({ ...initialState });
    function resetForm() {
      formState.value = { ...initialState };
    }

    return {
      authStore,
      lessionStore,

      /* FormState */
      formState,
      resetForm
    }
  },
  watch: {
    visible() {
      if (this.visible) {
        this.mode = 'form';
        this.selectedKeys = [];
        this.treeData = [];
        this.resourcesData = [];
      }
    }
  },
  data() {
    return {
      mode: 'form', // form|material

      /* Material */
      selectedKeys: [],
      treeData: [],
      resourcesData: [] // 展開tree時，把resources data加入
    }
  },
  methods: {
    /* Material */
    pickFromMaterial() {
      this.mode = 'material';
      this.getMaterialsData();
    },

    /* Api */
    async getMaterialsData() {
      const response = await api.v1.material.list({
        user_id: this.authStore.info.uid
      });

      response.data.result.forEach(item => {
        const treeItem = {
          key: item.id,
          title: item.name,
          selectable: false
        }
        this.treeData.push(treeItem);
      });
    },
    async getMaterialInfo(treeNode) {
      const materialId = treeNode.key;
      const response = await api.v1.material.info(materialId);

      const resourcesData = [];
      response.data.result.resources.forEach(item => {
        if (item.type === 'hw') {
          const leafItem = {
            title: item.data.name,
            key: item.id,
            isLeaf: true
          }
          resourcesData.push(leafItem);
          this.resourcesData.push(item);
        }
      });

      treeNode.dataRef.children = resourcesData;
      if (!resourcesData.length) {
        this.treeData.forEach(item => {
          if (item.key === materialId) {
            item.isLeaf = false;
            item.disabled = true;
          }
        });
      }
    },
  
    /* Functions */
    async handleOk() {
      const homeworksData = this.lessionStore.data.homeworks.map(x => ({...x}));

      if (this.mode === 'material') {
        if (!this.selectedKeys.length) {
          message.error('請選擇資源');
          return
        }

        let data = null;
        for(const idx in this.resourcesData) {
          const item = this.resourcesData[idx];
          if (item.id === this.selectedKeys[0]) {
            data = {
              id: Date.now(),
              resource_id: item.id,
              name: item.data.name,
              description: item.data.description
            }
            break
          }
        }

        if (data) this.formState = { ...data };
        else {
          message.error('從資源新增作業發生錯誤，請稍後再試，或聯絡管理人員');
          return
        }
      }
      else if (this.mode === 'form') {
        this.formState.id = Date.now();
      }

      // 更新回家作業
      try {
        homeworksData.push(this.formState);

        await api.v1.lession.updateOne(
          this.$route.params.lession_id,
          { homeworks: homeworksData }
        );

        message.success('新增回家作業成功');
      } catch (error) {
        message.error('新增回家作業失敗');
        console.log(error);
      }

      this.$emit('confirm');
      this.resetForm();
    },
    async handleCancel() {
      this.$emit('cancel');
      this.resetForm();
    }
  }
})
</script>