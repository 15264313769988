<template>
  <a-card class="lession-surveys nested-content__item">
    <a-typography-title class="mb-5" :level="3">課前紀錄</a-typography-title>

    <div class="user-card-list">
      <div class="user-card">
         <a-table
          :columns="columns"
          :data-source="rollcallsData"
          :defaultExpandAllRows="true"
          :pagination="false"
          rowKey="id"
          :scroll="{ x: 'max-content' }"
        >
           <template #bodyCell="{ column, text, record }">
              <template v-if="column.key === 'user'">
                <a-button
                  type="text"
                  @click="openUserReviewModal(record.user_id)"
                >
                  <a-avatar
                    v-if="!device.mobile"
                    :src="record.user.avatar!=null ? record.user.avatar.uri : require('@/assets/icons/avatar.png')"
                    style="margin-right:12px;"
                    size="small"
                  />
                  {{ record.user.name }} <span v-if="!device.mobile">({{ record.user.member_id }})</span>
                </a-button>
              </template>

              <template v-if="column.key === 'gender'">
                <template v-if="record.user.gender == 'male'">
                  <span>先生</span>
                </template>
                <template v-else-if="record.user.gender == 'female'">
                  <span>女士</span>
                </template>
              </template>

              <template v-if="column.key === 'age'">
                {{ calculateAge(record.user.birthdate) }}
              </template>

              <template v-if="column.key === 'content_1'">
                <!-- 收縮壓 -->
                <span
                  v-if="record.contents[0].value > 140"
                  :style="{ color: red[5], fontWeight: 500 }"
                >{{ record.contents[0].value }}</span>
                <span v-else>{{ record.contents[0].value }}</span>
              </template>

              <template v-if="column.key === 'content_2'">
                <!-- 舒張壓 -->
                <span
                  v-if="record.contents[1].value > 90"
                  :style="{ color: red[5], fontWeight: 500 }"
                >{{ record.contents[1].value }}</span>
                <span v-else>{{ record.contents[1].value }}</span>
              </template>

              <template v-if="column.key === 'content_3'">
                <!-- 心率 -->
                <span
                  v-if="record.contents[2].value > 100"
                  :style="{ color: red[5], fontWeight: 500 }"
                >{{ record.contents[2].value }}</span>
                <span v-else>{{ record.contents[2].value }}</span>
              </template>
           </template>
         </a-table>
      </div>
    </div>


    <UserReviewModal
      :visible="userReviewModal.visible"
      :userId="userReviewModal.userId"
      :courseId="$route.params.course_id"
      @close="handleUserReviewModalClose"
    />
  </a-card>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { red } from '@ant-design/colors'
import { createDeviceDetector } from 'next-vue-device-detector'
import api from '@/apis'
import UserReviewModal from '@/components/App/User/UserReviewModal.vue'

import { ScatterChart, useScatterChart } from 'vue-chart-3'
import { Chart, registerables } from "chart.js"

Chart.register(...registerables);

const columns = [{
  title: '學員',
  key: 'user'
}, {
  title: '性別',
  key: 'gender'
}, {
  title: '年齡',
  key: 'age'
}, {
  title: '收縮壓',
  key: 'content_1'
}, {
  title: '舒張壓',
  key: 'content_2',
}, {
  title: '心率',
  key: 'content_3',
}]

export default defineComponent({
  components: {
    UserReviewModal,
    ScatterChart
  },
  setup() {
    const route = useRoute();
    const device = createDeviceDetector();

    /* Data */
    const rollcallsData = ref([]);

    /* Api */
    const getRollcallsData = (async () => {
      const response = await api.v1.lession.findRollcalls(route.params.course_id, route.params.lession_id, {
        type: 'lession_pre'
      });

      rollcallsData.value = response.data.result;
      console.log(response.data.result);
    });

    /* Mounted */
    onMounted(async () => {
      await getRollcallsData();
    });

    /* Chart */
    const testData = {
      datasets: [{
        label: 'Scatter Dataset',
        data: [{
          x: 56,
          y: 125
        }, {
          x: 53,
          y: 136
        }, {
          x: 60,
          y: 100
        }, {
          x: 50,
          y: 120
        }],
        backgroundColor: 'rgb(255, 99, 132)'
      }]
    };

    const options = {
      plugins: {
        legend: false
      },
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: '年齡（歲）'
          },
          min: 30,
          max: 70
        },
        y: {
          display: true,
          title: {
            display: true,
            text: '血壓（mmHg）'
          },
          min: 80,
          max: 160
        }
      }
    }

    const { scatterChartProps, scatterChartRef } = useScatterChart({
      chartData: testData,
      options,
    });

    return {
      device,
      
      /* Table */
      columns,

      /* Data */
      rollcallsData,

      /* Colors */
      red,

      scatterChartProps,
      scatterChartRef
    }
  },
  data() {
    return {
      /* User Review Modal */
      userReviewModal: {
        visible: false,
        userId: ''
      }
    }
  },
  methods: {
    /* Utils */
    calculateAge(birthdate) {
      const ageDifMs = Date.now() - new Date(birthdate).getTime();
      const ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },

    /* User Review Modal */
    openUserReviewModal(userId) {
      this.userReviewModal.visible = true;
      this.userReviewModal.userId = userId;
    },
    handleUserReviewModalClose() {
      this.userReviewModal.visible = false;
      this.userReviewModal.userId = '';
    }
  }
})
</script>