<template>
  <a-modal
    :visible="visible"
    title="新增影片資源"
    okText="確認"
    cancelText="取消"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <template v-if="mode === 'form'">
      <!-- Coach -->
      <template v-if="authStore.info.role == 2">
        <div class="py-4" align="center">
          <a-button size="large" @click="pickFromMaterial">
            <FolderFilled :style="{ color: '#424242', fontSize: '1rem' }" />
            從資源庫選取
          </a-button>
        </div>
        <a-divider>或</a-divider>
      </template>
      <!-- Coach -->

      <a-form
        :model="formState"
        layout="vertical"
        autocomplete="off"
      >
        <a-form-item
          label="影片名稱"
          name="name"
          :rules="[{ required: true, message: '請輸入影片名稱' }]"
        >
          <a-input v-model:value="formState.name" size="large" />
        </a-form-item>

        <a-form-item
          label="影片描述"
          name="description"
          :rules="[{ required: true, message: '請輸入影片描述' }]"
        >
          <a-input v-model:value="formState.description" size="large" />
        </a-form-item>

        <a-form-item
          label="影片連結 (Youtube)"
          name="url"
          :rules="[{ required: true, message: '請輸入影片連結' }]"
        >
          <a-input v-model:value="formState.url" size="large" />
        </a-form-item>

        <a-form-item v-if="isAvailableUrl">
          <div class="preview">
            <iframe
              width="100%" height="250"
              :src="`https://www.youtube.com/embed/${youtubeIdParser(formState.url)}`"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </a-form-item>
      </a-form>
    </template>

    <template v-if="mode === 'material'">
      <a-directory-tree
        v-model:selectedKeys="selectedKeys"
        :load-data="getMaterialInfo"
        :tree-data="treeData"
      />
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { message } from 'ant-design-vue'
import { FolderFilled } from '@ant-design/icons-vue'
import { useAuthStore } from '@/store/auth'
import { useLessionStore } from '../store'
import api from '@/apis'

export default defineComponent({
  props: ['visible'],
  components: {
    FolderFilled
  },
  setup() {
    const authStore = useAuthStore();
    const lessionStore = useLessionStore();

    const initialState = {
      resource_id: 0,
      name: '',
      description: '',
      url: ''
    }
    const formState = ref({ ...initialState });
    function resetForm() {
      formState.value = { ...initialState };
    }

    function youtubeIdParser(url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return (match&&match[7].length==11)? match[7] : false;
    }
    
    return {
      authStore,
      lessionStore,

      /* FormState */
      formState,
      resetForm,

      youtubeIdParser
    }
  },
  watch: {
    visible() {
      if (this.visible) {
        this.mode = 'form';
        this.selectedKeys = [];
        this.treeData = [];
        this.resourcesData = [];
      }
    }
  },
  data() {
    return {
      mode: 'form', // form|material

      /* Material */
      selectedKeys: [],
      treeData: [],
      resourcesData: [] // 展開tree時，把resources data加入
    }
  },
  computed: {
    isAvailableUrl() {
      if (this.formState.url.includes('https://') && this.formState.url.includes('youtube')) return true
      return false
    }
  },
  methods: {
    /* Material */
    pickFromMaterial() {
      this.mode = 'material';
      this.getMaterialsData();
    },

    /* Api */
    async getMaterialsData() {
      const response = await api.v1.material.list({
        user_id: this.authStore.info.uid
      });

      response.data.result.forEach(item => {
        const treeItem = {
          key: item.id,
          title: item.name,
          selectable: false
        }
        this.treeData.push(treeItem);
      });
    },
    async getMaterialInfo(treeNode) {
      const materialId = treeNode.key;
      const response = await api.v1.material.info(materialId);

      const resourcesData = [];
      response.data.result.resources.forEach(item => {
        if (item.type === 'video') {
          const leafItem = {
            title: item.data.name,
            key: item.id,
            isLeaf: true
          }
          resourcesData.push(leafItem);
          this.resourcesData.push(item);
        }
      });

      treeNode.dataRef.children = resourcesData;
      if (!resourcesData.length) {
        this.treeData.forEach(item => {
          if (item.key === materialId) {
            item.isLeaf = false;
            item.disabled = true;
          }
        });
      }
    },

    /* Functios */
    async handleOk() {
      const videosData = this.lessionStore.data.videos.map(x => ({...x}));

      if (this.mode === 'material') {
        if (!this.selectedKeys.length) {
          message.error('請選擇資源');
          return
        }

        let data = null;
        for(const idx in this.resourcesData) {
          const item = this.resourcesData[idx];
          if (item.id === this.selectedKeys[0]) {
            data = {
              resource_id: item.id,
              name: item.data.name,
              description: item.data.description,
              url: item.data.url
            }
            break
          }
        }

        if (data) this.formState = { ...data };
        else {
          message.error('從資源新增影片發生錯誤，請稍後再試，或聯絡管理人員');
          return
        }
      }

      try {
        videosData.push(this.formState);

        await api.v1.lession.updateOne(
          this.$route.params.lession_id,
          { videos: videosData }
        );

        message.success('新增影片資源成功');
      } catch (error) {
        message.error('新增影片資源失敗');
        console.log(error);
      }

      this.$emit('confirm');
      this.resetForm();
    },
    async handleCancel() {
      this.$emit('cancel');
      this.resetForm();
    }
  }
})
</script>